import ExpandMore from '@mui/icons-material/ExpandMore';
import PersonIcon from '@mui/icons-material/Person';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import useAuth from '../app/auth';

export function UserMenu() {
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const { t } = useTranslation('metamodel', { keyPrefix: 'authentication' });
  const { user, login, logout } = useAuth();

  if (!user) {
    return <Button variant="contained" onClick={login}>{t('login')}</Button>;
  }

  return (
    <>
      <PersonIcon />
      <Typography id="header-username">{user.name}</Typography>
      <IconButton
        id="nav-user-menu-button"
        onClick={event => { setAnchorEl(event.currentTarget); }}
        color="inherit"
        aria-controls="menu-appbar"
        aria-haspopup="true"
      >
        <ExpandMore />
      </IconButton>
      <Menu
        className="header-menu"
        id="menu-appbar"
        anchorEl={anchorEl}
        open={anchorEl !== undefined}
        onClose={() => { setAnchorEl(undefined); }}
      >
        <MenuItem onClick={logout}>{t('logout')}</MenuItem>
      </Menu>
    </>
  );
}
