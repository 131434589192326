import { NodeTypeContribution } from '@AresEkb/sirius-components-diagrams';
import type { NodeTypeRegistry } from '@AresEkb/sirius-web-application';
import type { NodeProps } from '@xyflow/react';
import type { FC } from 'react';

import { FreeFormNode } from '../diagrams/nodes/FreeFormNode';
import { ListNode } from '../diagrams/nodes/ListNode';

// TODO Fix types
export const nodeTypeRegistry: NodeTypeRegistry = {
  nodeLayoutHandlers: [],
  nodeConverters: [],
  nodeTypeContributions: [
    <NodeTypeContribution
      key="freeFormNode"
      component={FreeFormNode as unknown as FC<NodeProps>}
      type="freeFormNode"
    />,
    <NodeTypeContribution key="listNode" component={ListNode as unknown as FC<NodeProps>} type="listNode" />,
  ],
};
