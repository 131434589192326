import type { ReactNode } from 'react';
import { createContext, useContext, useEffect, useState } from 'react';

export interface AuthContextType {
  user?: User;
  error?: unknown;
  login: () => void;
  logout: () => void;
}

export interface User {
  name: string;
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

interface AuthProviderProps {
  httpOrigin: string;
  updateInterval?: number;
  children: ReactNode;
}

export function AuthProvider({ httpOrigin, updateInterval = 60000, children }: AuthProviderProps) {
  const [user, setUser] = useState<User>();
  const [error, setError] = useState<unknown>();

  useEffect(() => {
    async function getUser() {
      try {
        const response = await fetch(`${httpOrigin}/api/users/current`, { credentials: 'include' });
        if (response.status === 200) {
          setUser((await response.json()) as User);
        } else if (response.status === 204) {
          setUser(undefined);
        }
        setError(undefined);
      } catch (e) {
        setUser(undefined);
        setError(e);
      }
    }

    void getUser();
    const interval = setInterval(() => void getUser(), updateInterval);
    return () => clearInterval(interval);
  }, [httpOrigin, updateInterval]);

  function login() {
    window.location.assign(`${httpOrigin}/authenticate?uri=${window.location.href}`);
  }

  function logout() {
    window.location.assign(`${httpOrigin}/logout`);
  }

  return <AuthContext.Provider value={{ user, error, login, logout }}>{children}</AuthContext.Provider>;
}

export default function useAuth() {
  return useContext(AuthContext);
}
