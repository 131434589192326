import type { DiagramContextValue, NodeData } from '@AresEkb/sirius-components-diagrams';
import { DiagramContext } from '@AresEkb/sirius-components-diagrams';
import { NodeResizeControl, NodeResizer as XyflowNodeResizer } from '@xyflow/react';
import { ResizeControlVariant } from '@xyflow/system';
import { memo, useContext } from 'react';

import './NodeResizer.css';

export interface NodeResizerProps {
  data: NodeData;
  visible: boolean;
}

export const NodeResizer = memo(function NodeResizer({ data, visible }: NodeResizerProps) {
  const { readOnly } = useContext<DiagramContextValue>(DiagramContext);

  if (readOnly || !visible) {
    return;
  }

  if (data.isListChild) {
    return (
      <NodeResizeControl
        className="node-resizer-line"
        variant={ResizeControlVariant.Line}
        position="bottom"
        shouldResize={() => !data.isBorderNode}
      />
    );
  }

  if (data.nodeDescription.userResizable === 'BOTH') {
    return (
      <XyflowNodeResizer
        lineClassName="node-resizer-line"
        handleClassName="node-resizer-handle"
        shouldResize={() => !data.isBorderNode}
        keepAspectRatio={data.nodeDescription.keepAspectRatio}
      />
    );
  }

  if (data.nodeDescription.userResizable === 'HORIZONTAL') {
    return (
      <>
        <NodeResizeControl
          className="node-resizer-line"
          variant={ResizeControlVariant.Line}
          position="left"
          shouldResize={() => !data.isBorderNode}
          keepAspectRatio={data.nodeDescription.keepAspectRatio}
        />
        <NodeResizeControl
          className="node-resizer-line"
          variant={ResizeControlVariant.Line}
          position="right"
          shouldResize={() => !data.isBorderNode}
          keepAspectRatio={data.nodeDescription.keepAspectRatio}
        />
      </>
    );
  }

  if (data.nodeDescription.userResizable === 'VERTICAL') {
    return (
      <>
        <NodeResizeControl
          className="node-resizer-line"
          variant={ResizeControlVariant.Line}
          position="top"
          shouldResize={() => !data.isBorderNode}
          keepAspectRatio={data.nodeDescription.keepAspectRatio}
        />
        <NodeResizeControl
          className="node-resizer-line"
          variant={ResizeControlVariant.Line}
          position="bottom"
          shouldResize={() => !data.isBorderNode}
          keepAspectRatio={data.nodeDescription.keepAspectRatio}
        />
      </>
    );
  }
});
