import type { ServerContextValue } from '@AresEkb/sirius-components-core';
import { ServerContext } from '@AresEkb/sirius-components-core';
import {
  BorderNodePosition,
  ConnectionCreationHandles,
  ConnectionHandles,
  ConnectionTargetHandle,
  DiagramElementPalette,
  Label,
  useConnectorNodeStyle,
  useDrop,
  useDropNodeStyle,
  useRefreshConnectionHandles,
} from '@AresEkb/sirius-components-diagrams';
import type { Node, NodeProps } from '@xyflow/react';
import cx from 'classnames';
import { memo, useContext } from 'react';

import { useDelayedBoolean } from '../../hooks/useDelayedBoolean';

import type { FreeFormNodeData, NodeComponentsMap } from './Node.types';
import { NodeResizer } from './NodeResizer';

import './FreeFormNode.css';

export const FreeFormNode: NodeComponentsMap['freeFormNode'] = memo(function FreeFormNode({
  data,
  id,
  selected,
  dragging,
}: NodeProps<Node<FreeFormNodeData>>) {
  const { httpOrigin } = useContext<ServerContextValue>(ServerContext);
  const { onDrop, onDragOver } = useDrop();
  const { style: connectionFeedbackStyle } = useConnectorNodeStyle(id, data.nodeDescription.id);
  const { style: dropFeedbackStyle } = useDropNodeStyle(data.isDropNodeTarget, data.isDropNodeCandidate, dragging);
  const hovered = useDelayedBoolean(data.isHovered, 1000, [id]);

  useRefreshConnectionHandles(id, data.connectionHandles);

  const borderNodeClass = getBorderNodeClass(data);
  const borderRadius = data.style.borderRadius ? data.style.borderRadius : undefined;
  const backgroundImage = data.imageURL ? `url(${httpOrigin}${data.imageURL})` : undefined;

  return (
    <>
      <NodeResizer data={data} visible={hovered} />
      <div
        className={cx('free-form-node', { selected, hovered, faded: data.faded })}
        // eslint-disable-next-line react/forbid-dom-props
        style={{ ...data.style, ...connectionFeedbackStyle, ...dropFeedbackStyle }}
        onDragOver={onDragOver}
        onDrop={(e) => onDrop(e, id)}
        data-testid={`FreeForm - ${data.targetObjectLabel}`}
      >
        <div
          className={cx('free-form-node-image', borderNodeClass)}
          // eslint-disable-next-line react/forbid-dom-props
          style={{ borderRadius, backgroundImage }}
        />
        {data.insideLabel && <Label diagramElementId={id} label={data.insideLabel} faded={data.faded} />}
        {selected && hovered ? (
          <DiagramElementPalette
            diagramElementId={id}
            targetObjectId={data.targetObjectId}
            labelId={getLabelId(data)}
          />
        ) : undefined}
        {hovered ? <ConnectionCreationHandles nodeId={id} /> : undefined}
        <ConnectionTargetHandle nodeId={id} nodeDescription={data.nodeDescription} isHovered={hovered} />
        <ConnectionHandles connectionHandles={data.connectionHandles} />
      </div>
      {data.outsideLabels.BOTTOM_MIDDLE && (
        <Label diagramElementId={id} label={data.outsideLabels.BOTTOM_MIDDLE} faded={data.faded} />
      )}
    </>
  );
});

function getBorderNodeClass(data: FreeFormNodeData): string | undefined {
  if (data.isBorderNode && data.positionDependentRotation) {
    switch (data.borderNodePosition) {
      case BorderNodePosition.NORTH:
        return 'border-node-position-north';
      case BorderNodePosition.EAST:
        return 'border-node-position-east';
      case BorderNodePosition.SOUTH:
        return 'border-node-position-south';
    }
  }
}

function getLabelId(nodeData: FreeFormNodeData): string | null {
  if (nodeData.insideLabel) {
    return nodeData.insideLabel.id;
  }
  if (nodeData.outsideLabels.BOTTOM_MIDDLE) {
    return nodeData.outsideLabels.BOTTOM_MIDDLE.id;
  }
  return null;
}
