import type {
  ComponentExtension,
  DataExtension,
  ExtensionRegistryMergeStrategy,
} from '@AresEkb/sirius-components-core';
import { ExtensionRegistry } from '@AresEkb/sirius-components-core';
import type { EdgeData, NodeData, ReactFlowPropsCustomizer } from '@AresEkb/sirius-components-diagrams';
import { diagramRendererReactFlowPropsCustomizerExtensionPoint } from '@AresEkb/sirius-components-diagrams';
import { treeItemContextMenuEntryExtensionPoint } from '@AresEkb/sirius-components-trees';
import type { ProjectSettingTabContribution } from '@AresEkb/sirius-web-application';
import {
  footerExtensionPoint,
  httpOptionsConfigurersExtensionPoint,
  navigationBarIconExtensionPoint,
  navigationBarMenuIconExtensionPoint,
  navigationBarRightContributionExtensionPoint,
  projectSettingsTabExtensionPoint,
} from '@AresEkb/sirius-web-application';
import PeopleIcon from '@mui/icons-material/People';
import type { Edge, Node, ReactFlowProps } from '@xyflow/react';

import { DocumentTreeItemApiContextMenuContribution } from '../explorer/DocumentTreeItemApiContextMenuContribution';
import { ModelElementTransformationContextMenuContribution } from '../explorer/ModelElementTransformationContextMenuContribution';
import { UserMenu } from '../navigationBar/UserMenu';
import LogoIcon from '../theme/logo2.svg?react';
import { ProjectMembers } from '../views/project-members/members/ProjectMembers';

export const extensionRegistry = new ExtensionRegistry();

extensionRegistry.putData(httpOptionsConfigurersExtensionPoint, {
  identifier: 'apolloClient#httpOptionsConfigurers',
  data: [(options) => ({ ...options, credentials: 'include' })],
});

extensionRegistry.addComponent(navigationBarIconExtensionPoint, {
  identifier: 'navigationBar#icon',
  Component: () => <LogoIcon width="24" />,
});

extensionRegistry.addComponent(navigationBarRightContributionExtensionPoint, {
  identifier: `siriusweb_${navigationBarRightContributionExtensionPoint.identifier}_omnibox`,
  Component: () => null,
});
extensionRegistry.addComponent(navigationBarRightContributionExtensionPoint, {
  identifier: `metamodel_${navigationBarRightContributionExtensionPoint.identifier}_usermenu`,
  Component: () => <UserMenu />,
});

extensionRegistry.addComponent(navigationBarMenuIconExtensionPoint, {
  identifier: 'navigationBarMenu#icon',
  Component: () => null,
});

extensionRegistry.putData(projectSettingsTabExtensionPoint, {
  identifier: `metamodel_${projectSettingsTabExtensionPoint.identifier}`,
  data: [
    {
      id: 'members',
      title: 'Members',
      icon: <PeopleIcon />,
      component: ProjectMembers,
    },
  ],
});

extensionRegistry.addComponent(footerExtensionPoint, {
  identifier: 'footer',
  Component: () => null,
});

extensionRegistry.addComponent(treeItemContextMenuEntryExtensionPoint, {
  identifier: `metamodel_${treeItemContextMenuEntryExtensionPoint.identifier}_document`,
  Component: DocumentTreeItemApiContextMenuContribution,
});
extensionRegistry.addComponent(treeItemContextMenuEntryExtensionPoint, {
  identifier: `metamodel_${treeItemContextMenuEntryExtensionPoint.identifier}_element`,
  Component: ModelElementTransformationContextMenuContribution,
});

function reactFlowPropsCustomizer(props: ReactFlowProps<Node<NodeData>, Edge<EdgeData>>) {
  return { ...props, proOptions: { hideAttribution: true } };
}
const diagramPanelExtension: DataExtension<ReactFlowPropsCustomizer[]> = {
  identifier: `metamodel_${diagramRendererReactFlowPropsCustomizerExtensionPoint.identifier}`,
  data: [reactFlowPropsCustomizer],
};
extensionRegistry.putData(diagramRendererReactFlowPropsCustomizerExtensionPoint, diagramPanelExtension);

// const routes: RouteProps[] = [
//   {
//     path: '/acceleo',
//     component: () => <TextEditor />,
//   },
// ];

// registry.putData(routerExtensionPoint, {
//   identifier: `siriusWeb_${routerExtensionPoint.identifier}`,
//   data: routes,
// });

export class MetamodelExtensionRegistryMergeStrategy implements ExtensionRegistryMergeStrategy {
  public mergeComponentExtensions(
    _identifier: string,
    existingValues: ComponentExtension<unknown>[],
    newValues: ComponentExtension<unknown>[],
  ): ComponentExtension<unknown>[] {
    const overriddenIds = new Set(existingValues.map((v) => v.identifier));
    return [...existingValues.filter((v) => !overriddenIds.has(v.identifier)), ...newValues];
  }

  public mergeDataExtensions(
    identifier: string,
    existingValue: DataExtension<unknown>,
    newValue: DataExtension<unknown>,
  ): DataExtension<unknown> {
    if (identifier === projectSettingsTabExtensionPoint.identifier) {
      return {
        ...existingValue,
        data: [
          ...(existingValue.data as ProjectSettingTabContribution[]),
          ...(newValue.data as ProjectSettingTabContribution[]),
        ],
      };
    }
    return newValue;
  }
}
