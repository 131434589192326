import type { ServerContextValue } from '@AresEkb/sirius-components-core';
import { ServerContext } from '@AresEkb/sirius-components-core';
import type { TreeItemContextMenuComponentProps } from '@AresEkb/sirius-components-trees';
import ApiIcon from '@mui/icons-material/Api';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import { Fragment, useContext, useState } from 'react';

export function DocumentTreeItemApiContextMenuContribution(props: TreeItemContextMenuComponentProps) {
  const [showDialog, setShowDialog] = useState(false);

  if (!props.treeId.startsWith('explorer://') || !props.item.kind.startsWith('siriusWeb://document')) {
    return null;
  }

  return (
    <Fragment key="document-tree-item-api-context-menu-contribution">
      <MenuItem key="view-api-info" data-testid="view-api-info" onClick={() => setShowDialog(true)} aria-disabled>
        <ListItemIcon>
          <ApiIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="View API information" />
      </MenuItem>
      {showDialog ? (
        <ApiInformationDialog
          projectId={props.editingContextId}
          documentId={props.item.id}
          onClose={() => setShowDialog(false)}
        />
      ) : undefined}
    </Fragment>
  );
}

interface ApiInformationDialogProps {
  projectId: string;
  documentId: string;
  onClose: () => void;
}

function ApiInformationDialog(props: ApiInformationDialogProps) {
  const { httpOrigin } = useContext<ServerContextValue>(ServerContext);
  const [copied, setCopied] = useState(false);

  const documentUrl = `${httpOrigin}/api/rest/projects/${props.projectId}/documents/${props.documentId}`;

  async function copyUrl() {
    try {
      await navigator.clipboard.writeText(documentUrl);
      setCopied(true);
      setTimeout(() => setCopied(false), 1000);
    } catch (err) {
      console.error('Failed to copy text', err);
    }
  }

  return (
    <Dialog open onClose={props.onClose}>
      <DialogTitle>API information</DialogTitle>
      <DialogContent>
        <p>
          {documentUrl}
          <Tooltip title="Copy to clipboard" placement="top">
            <span>
              <Tooltip title="Copied" placement="bottom" open={copied}>
                <IconButton onClick={copyUrl}>
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
            </span>
          </Tooltip>
        </p>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={props.onClose}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
