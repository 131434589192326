import type { ServerContextValue } from '@AresEkb/sirius-components-core';
import { ServerContext } from '@AresEkb/sirius-components-core';
import type { TreeItemContextMenuComponentProps } from '@AresEkb/sirius-components-trees';
import TransformIcon from '@mui/icons-material/Transform';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import { Fragment, useContext } from 'react';

export function ModelElementTransformationContextMenuContribution(props: TreeItemContextMenuComponentProps) {
  const { httpOrigin } = useContext<ServerContextValue>(ServerContext);

  if (!props.treeId.startsWith('explorer://') || !props.item.kind.startsWith('siriusComponents://semantic')) {
    return null;
  }

  async function transform() {
    await fetch(`${httpOrigin}/api/rest/model-elements/${props.item.id}/transform`, {
      method: 'POST',
      credentials: 'include',
    });
  }

  return (
    <Fragment key="model-element-transformation-context-menu-contribution">
      <MenuItem key="transformation" data-testid="transformation" aria-disabled>
        <ListItemIcon>
          <TransformIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Transform" onClick={transform} />
      </MenuItem>
    </Fragment>
  );
}
