import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { MemberTable } from './MemberTable';
import { useProjectMembers } from './useProjectMembers';
import type { GQLMember } from './useProjectMembers.types';

const useProjectMembersStyles = makeStyles()((theme) => ({
  membersViewContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(5),
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    '& > *': {
      marginLeft: theme.spacing(2),
    },
  },
}));

export function ProjectMembers() {
  const { classes } = useProjectMembersStyles();
  const { t } = useTranslation('metamodel', { keyPrefix: 'member.list' });
  const { projectId } = useParams<'projectId'>();

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { data, loading, refreshMembers } = useProjectMembers(projectId!);

  if (loading || !data) {
    return <div />;
  }

  const members: GQLMember[] = data.viewer.project?.members ?? [];
  let main;
  if (members.length > 0) {
    main = <MemberTable members={members} onMembersUpdated={refreshMembers} />;
  } else {
    main = (
      <Grid container justifyContent="center">
        <Grid item xs={6}>
          <Typography variant="h6" align="center" gutterBottom>
            {t('empty')}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <div className={classes.membersViewContainer}>
      <div className={classes.header}>
        <Typography variant="h4">{t('title')}</Typography>
      </div>
      {main}
    </div>
  );
}
