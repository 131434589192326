import { Radio } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import type { ChangeEvent } from 'react';

import type { MemberRowProps } from './MemberRow.types';

export function MemberRow({ member, onChange: updateRole }: MemberRowProps) {
  function onChange(event: ChangeEvent<HTMLInputElement>) {
    updateRole(member.id, event.target.value);
  }

  return (
    <TableRow hover>
      <TableCell>
        {member.name}
      </TableCell>
      <TableCell align='center'>
        <Radio name={member.id} value='none' checked={member.role === 'NONE'} onChange={onChange} />
      </TableCell>
      <TableCell align='center'>
        <Radio name={member.id} value='viewer' checked={member.role === 'VIEWER'} onChange={onChange} />
      </TableCell>
      <TableCell align='center'>
        <Radio name={member.id} value='editor' checked={member.role === 'EDITOR'} onChange={onChange} />
      </TableCell>
      <TableCell align='center'>
        <Radio name={member.id} value='owner' checked={member.role === 'OWNER'} onChange={onChange} />
      </TableCell>
    </TableRow>
  );
}
