import {
  ConnectionCreationHandles,
  ConnectionHandles,
  ConnectionTargetHandle,
  DiagramElementPalette,
  Label,
  useConnectorNodeStyle,
  useDrop,
  useDropNodeStyle,
  useRefreshConnectionHandles,
} from '@AresEkb/sirius-components-diagrams';
import type { Node, NodeProps } from '@xyflow/react';
import cx from 'classnames';
import { memo } from 'react';

import { useDelayedBoolean } from '../../hooks/useDelayedBoolean';

import type { NodeComponentsMap, ListNodeData } from './Node.types';
import { NodeResizer } from './NodeResizer';

import './ListNode.css';

export const ListNode: NodeComponentsMap['listNode'] = memo(function ListNode({
  data,
  id,
  selected,
  dragging,
}: NodeProps<Node<ListNodeData>>) {
  const { onDrop, onDragOver } = useDrop();
  const { style: connectionFeedbackStyle } = useConnectorNodeStyle(id, data.nodeDescription.id);
  const { style: dropFeedbackStyle } = useDropNodeStyle(data.isDropNodeTarget, data.isDropNodeCandidate, dragging);
  const hovered = useDelayedBoolean(data.isHovered, 1000, [id]);

  useRefreshConnectionHandles(id, data.connectionHandles);

  return (
    <>
      <NodeResizer data={data} visible={hovered} />
      <div
        className={cx('list-node', { selected, hovered, faded: data.faded })}
        // eslint-disable-next-line react/forbid-dom-props
        style={{ ...data.style, ...connectionFeedbackStyle, ...dropFeedbackStyle }}
        onDragOver={onDragOver}
        onDrop={(event) => onDrop(event, id)}
        data-testid={`List - ${data.insideLabel?.text ?? ''}`}
      >
        {data.insideLabel ? <Label diagramElementId={id} label={data.insideLabel} faded={data.faded} /> : undefined}
        {selected && hovered ? (
          <DiagramElementPalette
            diagramElementId={id}
            targetObjectId={data.targetObjectId}
            labelId={data.insideLabel?.id ?? null}
          />
        ) : undefined}
        {hovered ? <ConnectionCreationHandles nodeId={id} /> : undefined}
        <ConnectionTargetHandle nodeId={id} nodeDescription={data.nodeDescription} isHovered={hovered} />
        <ConnectionHandles connectionHandles={data.connectionHandles} />
      </div>
    </>
  );
});
