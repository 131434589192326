import { useMultiToast } from '@AresEkb/sirius-components-core';
import { gql, useQuery } from '@apollo/client';
import { useEffect } from 'react';

import type {
  GQLGetProjectMembersQueryData,
  GQLGetProjectMembersQueryVariables,
  UseProjectMembersValue,
} from './useProjectMembers.types';

const getProjectMembersQuery = gql`
  query projectMembers($projectId: ID!) {
    viewer {
      project(projectId: $projectId) {
        members {
          id
          name
          role
        }
      }
    }
  }
`;

export function useProjectMembers(projectId: string): UseProjectMembersValue {
  const variables: GQLGetProjectMembersQueryVariables = { projectId };

  const { data, loading, error, refetch } = useQuery<GQLGetProjectMembersQueryData, GQLGetProjectMembersQueryVariables>(
    getProjectMembersQuery,
    { variables },
  );

  const { addErrorMessage } = useMultiToast();
  useEffect(() => {
    if (error) {
      addErrorMessage(error.message);
    }
  }, [addErrorMessage, error]);

  function refreshMembers() {
    refetch(variables).catch(() => {
      addErrorMessage('Error');
    });
  }

  return { data: data ?? null, loading, refreshMembers };
}
