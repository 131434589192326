import { siriusComponentsCoreEn } from '@AresEkb/sirius-components-core';
import { siriusComponentsDiagramsEn } from '@AresEkb/sirius-components-diagrams';
import { siriusComponentsFormDescriptionEditorsEn } from '@AresEkb/sirius-components-formdescriptioneditors';
import { siriusComponentsFormsEn } from '@AresEkb/sirius-components-forms';
import { siriusComponentsTreesEn } from '@AresEkb/sirius-components-trees';
import { siriusComponentsValidationEn } from '@AresEkb/sirius-components-validation';
import { siriusComponentsWidgetReferenceEn } from '@AresEkb/sirius-components-widget-reference';
import { siriusWebApplicationEn } from '@AresEkb/sirius-web-application';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import { default as metamodelEn } from '../locales/en.json';
import { default as siriusComponentsCoreRu } from '../locales/ru/siriusComponentsCore.json';
import { default as siriusComponentsDiagramsRu } from '../locales/ru/siriusComponentsDiagrams.json';
import { default as siriusComponentsFormDescriptionEditorsRu } from '../locales/ru/siriusComponentsFormDescriptionEditors.json';
import { default as siriusComponentsFormsRu } from '../locales/ru/siriusComponentsForms.json';
import { default as siriusComponentsTreesRu } from '../locales/ru/siriusComponentsTrees.json';
import { default as siriusComponentsValidationRu } from '../locales/ru/siriusComponentsValidation.json';
import { default as siriusComponentsWidgetReferenceRu } from '../locales/ru/siriusComponentsWidgetReference.json';
import { default as siriusWebApplicationRu } from '../locales/ru/siriusWebApplication.json';
import { default as metamodelRu } from '../locales/ru.json';

// eslint-disable-next-line import-x/no-named-as-default-member
void i18next.use(initReactI18next).init({
  lng: 'ru',
  interpolation: {
    escapeValue: false, // React already protects from XSS
  },
  resources: {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    en: {
      ...siriusComponentsCoreEn,
      ...siriusComponentsDiagramsEn,
      ...siriusComponentsFormDescriptionEditorsEn,
      ...siriusComponentsFormsEn,
      ...siriusComponentsTreesEn,
      ...siriusComponentsValidationEn,
      ...siriusComponentsWidgetReferenceEn,
      ...siriusWebApplicationEn,
      ...metamodelEn,
    },
    ru: {
      ...siriusComponentsCoreRu,
      ...siriusComponentsDiagramsRu,
      ...siriusComponentsFormDescriptionEditorsRu,
      ...siriusComponentsFormsRu,
      ...siriusComponentsTreesRu,
      ...siriusComponentsValidationRu,
      ...siriusComponentsWidgetReferenceRu,
      ...siriusWebApplicationRu,
      ...metamodelRu,
    },
  },
});
export default i18next;
