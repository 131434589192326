/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
const { VITE_HTTP_SERVER_PORT, VITE_WS_SERVER_PORT } = import.meta.env;

export const httpOrigin = VITE_HTTP_SERVER_PORT?.length
  ? `${window.location.protocol}//${window.location.hostname}:${VITE_HTTP_SERVER_PORT}`
  : `${window.location.protocol}//${window.location.host}`;

const wsProtocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
export const wsOrigin = VITE_WS_SERVER_PORT?.length
  ? `${wsProtocol}//${window.location.hostname}:${VITE_WS_SERVER_PORT}`
  : `${wsProtocol}//${window.location.host}`;
