import { useMultiToast } from '@AresEkb/sirius-components-core';
import { gql, useMutation } from '@apollo/client';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { MemberRow } from './MemberRow';
import type { MemberTableProps } from './MemberTable.types';

const changeRoleMutation = gql`
  mutation changeProjectMemberRole($input: ChangeProjectMemberRoleInput!) {
    changeProjectMemberRole(input: $input) {
      __typename
      ... on ErrorPayload {
        message
      }
    }
  }
`;

export interface GQLChangeProjectMemberRoleMutationVariables {
  input: GQLChangeProjectMemberRoleMutationInput;
}

export interface GQLChangeProjectMemberRoleMutationInput {
  id: string;
  projectId: string;
  memberId: string;
  newRole: string;
}

export interface GQLChangeProjectMemberRoleMutationData {
  changeProjectMemberRole: GQLChangeProjectMemberRolePayload;
}

export interface GQLChangeProjectMemberRolePayload {
  __typename: string;
}

export interface GQLErrorPayload extends GQLChangeProjectMemberRolePayload {
  message: string;
}

function isErrorPayload(payload: GQLChangeProjectMemberRolePayload): payload is GQLErrorPayload {
  return payload.__typename === 'ErrorPayload';
}

export function MemberTable({ members, onMembersUpdated }: MemberTableProps) {
  const { t } = useTranslation('metamodel', { keyPrefix: 'member.list' });

  const { projectId: projectIdParam } = useParams<'projectId'>();

  const [changeRole, { data, error }] = useMutation<GQLChangeProjectMemberRoleMutationData>(changeRoleMutation);

  const { addErrorMessage } = useMultiToast();

  if (error) {
    addErrorMessage(error.message);
  }
  if (data) {
    const { changeProjectMemberRole } = data;
    if (isErrorPayload(changeProjectMemberRole)) {
      addErrorMessage(changeProjectMemberRole.message);
    }
  }
  if (projectIdParam === undefined) {
    return;
  }
  const projectId = projectIdParam;

  function onChange(memberId: string, newRole: string) {
    const variables: GQLChangeProjectMemberRoleMutationVariables = {
      input: {
        id: crypto.randomUUID(),
        projectId,
        memberId,
        newRole,
      },
    };
    changeRole({ variables })
      .then(onMembersUpdated)
      .catch(() => void addErrorMessage('error'));
  }

  return (
    <Paper>
      <TableContainer>
        <Table>
          <colgroup>
            <col width="40%" />
            <col width="15%" />
            <col width="15%" />
            <col width="15%" />
            <col width="15%" />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell variant="head">{t('name')}</TableCell>
              <TableCell variant="head" align="center">
                {t('none')}
              </TableCell>
              <TableCell variant="head" align="center">
                {t('viewer')}
              </TableCell>
              <TableCell variant="head" align="center">
                {t('editor')}
              </TableCell>
              <TableCell variant="head" align="center">
                {t('owner')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody data-testid="members">
            {members.map((member) => (
              <MemberRow key={member.id} member={member} onChange={onChange} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
