import { DiagramRepresentationConfiguration, SiriusWebApplication } from '@AresEkb/sirius-web-application';
import { createRoot } from 'react-dom/client';

import { AuthProvider } from './app/auth';
import { extensionRegistry, MetamodelExtensionRegistryMergeStrategy } from './app/extensionRegistry';
import { nodeTypeRegistry } from './app/nodeTypeRegistry';
import { httpOrigin, wsOrigin } from './app/url';
import { applicationTheme } from './theme/theme';

import './app/i18n';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
createRoot(document.getElementById('root')!).render(
  <AuthProvider httpOrigin={httpOrigin}>
    <SiriusWebApplication
      httpOrigin={httpOrigin}
      wsOrigin={wsOrigin}
      extensionRegistry={extensionRegistry}
      extensionRegistryMergeStrategy={new MetamodelExtensionRegistryMergeStrategy()}
      theme={applicationTheme}
    >
      <DiagramRepresentationConfiguration nodeTypeRegistry={nodeTypeRegistry} />
    </SiriusWebApplication>
  </AuthProvider>,
);
