import type { DependencyList } from 'react';
import { useEffect, useState } from 'react';

export function useDelayedBoolean(value: boolean, delay = 1000, deps: DependencyList = []) {
  const [state, setState] = useState(value);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    if (value) {
      setState(true);
    } else {
      timer = setTimeout(() => setState(false), delay);
    }
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delay, value, ...deps]);

  return state;
}
